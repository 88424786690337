/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withPrefix, graphql } from 'gatsby';

// Helpers
import { filterResults } from '../../helpers/helpers';
import { checkNull } from '../../helpers/check-null';

// Layouts
import Default from '../../layouts/default';
import Seo from '../../layouts/seo';

// Components
import Section from '../../components/section';
import Form from '../../components/form';
import InputSwitch from '../../components/input-switch';
import Select from '../../components/select';
import PageHeader from '../../components/page-header';
import InputField from '../../components/input-field';
import ResultListing from '../../components/result-listing';
import ShareResults from '../../components/share-results';
import Content, { HTMLContent } from "../../components/content-renderer";

// Helpers
import withShareResults from '../../helpers/with-share-results';

const NationalInsurance = (props) => {
	const { getShareLink, selectedYearParam } = withShareResults();
	const PageContent = HTMLContent || Content;

	const pageInfo = props.data.toolInfo;
    const definitions = props.data.definitions.nodes;
    const headerImage = pageInfo.frontmatter.image.relativePath;

	// React Redux hooks
	const dispatch = useDispatch();
	const figures = useSelector(state => state.yearlyFigures);
	const query = useSelector(state => state.nationalInsurance);

	useEffect(() => {
        return () => {
			dispatch({ type: 'RESET_YEAR' });
			dispatch({ type: 'RESET_NATIONAL_INSURANCE_SELECTIONS' });
		};
    }, []);

	 //  Applies url params on load
     useEffect(() => {
		if (selectedYearParam) {
			dispatch({ type: 'SET_YEAR', payload: selectedYearParam });
		}

		dispatch({ type: 'APPLY_NATIONAL_INSURANCE_URL_PARAMS' });
	}, [selectedYearParam]);

	// Bonus pay as a fixed amount or as a percentage of salary
	const bonusPay = query.bonusAsPercentage ? (checkNull(query.salary) * (checkNull(query.bonus) / 100)) : checkNull(query.bonus);

	const grossIncome = checkNull(query.salary) + checkNull(bonusPay);

	const calcGrossIncome = (query.paidPer === 'year') ? grossIncome :
		(query.paidPer === 'month') ? (grossIncome * 12) :
		(query.paidPer === 'fortnight') ? (grossIncome * 26) :
		(query.paidPer === 'week') ? (grossIncome * 52) :
		(query.paidPer === 'day') ? ((grossIncome * query.workingDays) * 52) :
		(query.paidPer === 'hour') ? (((grossIncome * query.workingHours) * query.workingDays) * 52) :
		null;

	// National Insurance calculation
	// https://www.which.co.uk/money/tax/national-insurance/national-insurance-rates-ajg9u9p48f2f
	let calcNationalInsurance = 0;
		
	// Class 1 rate (Employed)
	// Less than Threshold: 0%
	// Threshold-Higher Income Band: Basic Rate (%)
	// For earnings above Higher Income Band: Higher Rate (%)
	if (query.selfEmployed === false) {
		calcNationalInsurance = (calcGrossIncome < figures.niThreshold) ? '0.00' :
		(calcGrossIncome >= figures.niThreshold && calcGrossIncome <= figures.niHigherBand) ? ((calcGrossIncome - figures.niThreshold - 1) * figures.niBasicRate) :
		(calcGrossIncome > figures.niHigherBand) ? (((figures.niHigherBand - figures.niThreshold - 1) * figures.niBasicRate) + ((calcGrossIncome - figures.niHigherBand) * figures.niHigherRate)) :
		null;
	}
	// Class 2 and 4 rates (Self-employed)
	// Less than Class 2 Threshold: 0%
	// Threshold+ : Lower Rate (£) per week
	// Lower Income Band - Higher Income Band: Basic Rate (%)
	// For earnings above Higher Income Band: Higher Rate (%)
	else {
		calcNationalInsurance = (calcGrossIncome < figures.niClass2Threshold) ? '0.00' :
		(calcGrossIncome >= figures.niClass2Threshold && calcGrossIncome < figures.niThreshold) ? (figures.niClass2LowerRate * 52) : 
		(calcGrossIncome >= figures.niThreshold && calcGrossIncome <= figures.niHigherBand) ? (((calcGrossIncome - figures.niThreshold - 1) * figures.niClass2BasicRate) + (figures.niClass2LowerRate * 52)) : 
		(calcGrossIncome > figures.niHigherBand) ? (((figures.niHigherBand - figures.niThreshold - 1) * figures.niClass2BasicRate) + ((calcGrossIncome - figures.niHigherBand) * figures.niClass2HigherRate) + (figures.niClass2LowerRate * 52)) :
		null;
	}
    
    return (
        <Default>
            <Seo
                title={pageInfo.frontmatter.title}
                description={pageInfo.frontmatter.description}
            />
			
			<PageHeader
				heading={pageInfo.frontmatter.title}
				imageSource={`${withPrefix("/")}img/${headerImage}`}
				modalContent={(
					<PageContent content={pageInfo.html} />
				)}
				description={pageInfo.frontmatter.description}
				breadcrumbs={[
                    {name: 'Home', url: '/'},
                    {name: 'Personal finance', url: '/personal-finance'},
                ]}
			/>

			<Section label='Calculator'>
				<Form>
					<Form.Fieldset>
						<Form.Row>
							<InputField
								id='salary'
								labelText='Salary'
								modalHeading={definitions[2].frontmatter.title}
								modalContent={<PageContent content={definitions[2].html} />}
								placeholder='0'
								symbol='currency'
								min={0}
								onChange={(event) => dispatch({
                                    type: 'SET_NATIONAL_INSURANCE_SALARY',
                                    payload: event.target.value,
                                })}
								required
								value={query.salary}
							/>

							<InputField
								id='bonus'
								labelText='Bonus'
								modalHeading={definitions[0].frontmatter.title}
								modalContent={<PageContent content={definitions[0].html} />}
								placeholder='0'
								symbol={query.bonusAsPercentage ? 'percentage' : 'currency'}
								min={0}
								max={query.bonusAsPercentage ? 100 : undefined}
								onChange={(event) => dispatch({
                                    type: 'SET_NATIONAL_INSURANCE_BONUS',
                                    payload: event.target.value,
                                })}
								value={query.bonus}
								checkboxText='Bonus as %'
								checkboxOnChange={() => dispatch({
									type: 'SET_NATIONAL_INSURANCE_BONUS_AS_PERCENTAGE',
									payload: !query.bonusAsPercentage,
								})}
								checkboxValue={query.bonusAsPercentage}
							/>
		
							<Select
								labelText="Paid per"
								id="paid-select"
								onChange={(event) => dispatch({
                                    type: 'SET_NATIONAL_INSURANCE_PAID_PER',
                                    payload: event.target.value,
                                })}
								value={query.paidPer}
							>
								<Select.Option value="year">Year</Select.Option>
								<Select.Option value="month">Month</Select.Option>
								<Select.Option value="fortnight">Fortnight</Select.Option>
								<Select.Option value="week">Week</Select.Option>
								<Select.Option value="day">Day</Select.Option>
								<Select.Option value="hour">Hour</Select.Option>
							</Select>
						</Form.Row>
			
						<Form.Row>
							<InputField
								id='days-per-week'
								labelText='Working days per week'
								placeholder='0'
								min={1}
								max={7}
								onChange={(event) => dispatch({
                                    type: 'SET_NATIONAL_INSURANCE_WORKING_DAYS',
                                    payload: event.target.value,
                                })}
								value={query.workingDays}
							/>
		
							<InputField
								id='hours-per-day'
								labelText='Working hours per day'
								placeholder='0'
								min={1}
								max={24}
								onChange={(event) => dispatch({
                                    type: 'SET_NATIONAL_INSURANCE_WORKING_HOURS',
                                    payload: event.target.value,
                                })}
								value={query.workingHours}
							/>

							<Select
								labelText="Tax year"
								id="tax-year-select"
								onChange={(event) => dispatch({
									type: 'SET_YEAR',
									payload: event.target.value,
								})}
								value={figures.selectedYear}
							>
								<Select.Option value='2024'>2024/25</Select.Option>
								<Select.Option value='2023'>2023/24</Select.Option>
								<Select.Option value='2022'>2022/23</Select.Option>
								<Select.Option value='2021'>2021/22</Select.Option>
								<Select.Option value='2020'>2020/21</Select.Option>
								<Select.Option value='2019'>2019/20</Select.Option>
								<Select.Option value='2018'>2018/19</Select.Option>
								<Select.Option value='2017'>2017/18</Select.Option>
								<Select.Option value='2016'>2016/17</Select.Option>
							</Select>
						</Form.Row>

						<Form.Row>
							<InputSwitch
								id="self-employed-switch"
								onChange={() => dispatch({
                                    type: 'SET_NATIONAL_INSURANCE_SELF_EMPLOYED',
                                    payload: !query.selfEmployed,
                                })}
								checked={query.selfEmployed}
								modalHeading={definitions[3].frontmatter.title}
								modalContent={<PageContent content={definitions[3].html} />}
							>
								Self-employed
							</InputSwitch>
						</Form.Row>
					</Form.Fieldset>
				</Form>
			</Section>

			<Section
				heading='Your results'
				label='Calculation results'
				variants={['padded', 'secondary']}
			>
				<ResultListing
					select={[
						{name: 'Year', value: 'year'},
						{name: 'Month', value: 'month'},
						{name: 'Week', value: 'week'},
						{name: 'Day', value: 'day'},
						{name: 'Hour', value: 'hour'},
					]}
					selectID='results-filter'
					selectOnChange={(event) => dispatch({
						type: 'SET_NATIONAL_INSURANCE_FILTER_RESULTS',
						payload: event.target.value,
					})}
					selectValue={query.filterResults}
				>
					<ResultListing.Item
						heading='Gross Income'
						result={filterResults(calcGrossIncome, query.filterResults, query.workingDays, query.workingHours)}
						subheading={`per ${query.filterResults}`}
						modalHeading={definitions[1].frontmatter.title}
						modalContent={<PageContent content={definitions[1].html} />}
					/>

					<ResultListing.Item
						heading='National Insurance'
						result={filterResults(calcNationalInsurance, query.filterResults, query.workingDays, query.workingHours)}
						subheading={`per ${query.filterResults}`}
					/>
				</ResultListing>

				<div className='summary-card__controls'>
					<ShareResults url={getShareLink(query, figures)} />
				</div>
			</Section>
        </Default>
    );
};

export default NationalInsurance;

export const NationalInsuranceQuery = graphql`
    query NationalInsuranceQuery($id: String!) {
        toolInfo: markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                description
                image {
                    relativePath
                }
                title
            }
        }
		definitions: allMarkdownRemark(
            filter: {fileAbsolutePath: {
                regex: "/src/definitions/salary|src/definitions/bonus-pay|src/definitions/self-employment|src/definitions/gross-income/"
            }},
            sort: {order: ASC, fields: frontmatter___title}
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
            }
        }
    }
`;